import Option from "./Option";
import React, { useState, useEffect } from "react";
import { shuffleArray } from "./utils";

// import MathRenderer from "./MathRenderer";

export default function Question({question}) {
  const [optionsArr, setOptionsArr] = useState([]);

  const checkAnswer = (e, option) => {
    if (question.correct_options.includes(option)) {
      e.target.classList.add("bg-green-800");
    } else {
      e.target.classList.add("bg-red-800");
    }
  };

  useEffect(() => {
    setOptionsArr(shuffleArray(question.options));
  }, []);

  let image_link = (question.image_links.length > 0) ? question.image_links[1]: "" 

  return (
    <div>
        
      {/* <MathRenderer className="question-text bg-cover" mathExpression={question.question}>  </MathRenderer> */}

      <h5 className="font-outfit font-semibold lg:mx-40 md:mx-25 my-5 px-5 py-5 rounded-md bg-zinc-800">{question.question}</h5>
      <img className="mx-auto max-w-lg my-5 rounded-lg" src={`${image_link}`}></img>
      {optionsArr.map((option, index) => (
        <div 
          key={`${question._id}-${index}`}
          onClick={(e) => checkAnswer(e, option)}
          className="font-outfit font-medium lg:mx-40 sm:mx-25 my-5 rounded-md bg-zinc-800"
        >
          <Option option={option}
           ></Option>
        </div>
      ))}

    </div>
  );
}


