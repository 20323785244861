import React, { useState, useEffect } from 'react';
import './App.css';
import Question from './components/Question';
function App() {


  const base = 'https://d5f43de8-9957-4d81-bfdf-56a55fd9c97b-00-hqwn6oclaqso.sisko.replit.dev/questions?topicName=Algorithms&';
  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [questionsNumber, setQuestionsNumber] = useState(10);
  const handleNextPageClick = () => {
    setPageNumber(pageNumber+1);
    getQuestionsData(pageNumber)
  }

  const getQuestionsData = (pageNumber) => {
    console.log(pageNumber)
    fetch(`${base}pageNumber=${pageNumber}&limit=${questionsNumber}`)
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      return response.json();
    })
    .then(json => setData(json))
    .catch(error => 
      console.error(error));
  }
  useEffect(() => {
    getQuestionsData(pageNumber)
  }, []);

  return (
    

    <div className="App">  
    <div>{ data &&
        data.data.data.map(question => 
            <Question key={question._id} question = {question} /> 
      )}</div>

    <button onClick={handleNextPageClick}>Next Page</button>

    </div>

  );
  
}

export default App;

